/*
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-11 17:20:20
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-26 16:10:30
 * @FilePath: /web_artemisads/src/server/seller/products.js
 * @Description: 
 */
import client from '../client';
import { exportFile } from '../requestDownloadFile';

export default {
  getProductList: data => {
    return client.get('seller/product/list', { params: data }).then(res => res.data);
  },
  inactiveProduct: data => {
    return client.post('seller/product/inactive', data).then(res => res.data);
  },
  activeProduct: data => {
    return client.post('seller/product/active', data).then(res => res.data);
  },
  getCommissionConfig: data => {
    return client.get('commission/config', data).then(res => res.data);
  },
  getProductCommissionHistory: data => {
    return client.get('seller/product/commissionHistory', data).then(res => res.data);
  },
  setCommission: data => {
    return client.post('seller/product/commission', data).then(res => res.data);
  },
  // exportProduct: data => {
  //   return client.get('seller/product/export?' + Object.keys(data).map(key => {
  //     return `${key}=${data[key]}`
  //   }).join('&')).then(res => res.data);
  // },
  exportProduct: data => {
    return exportFile('seller/product/export', data.qs, data.fileName);
  },
  downloadProduct: () => {
    return exportFile('seller/product/deal/template/download', {}, 'Product_deal_template.csv');
  },
  exportCommissionHistory: data => {
    return exportFile('seller/product/exportCommissionHistory', data.qs, data.fileName);
  },
  exportDownloadProductTemplate: data => {
    return exportFile('seller/product/downloadProductTemplate', data.qs, data.fileName);
  },
  preCommissionCsv: data => {
    return client.post('seller/product/preCommissionCsv', data).then(res => res.data);
  },
  updaloadProductDealsCsv: data => {
    return client.post('seller/product/deal/upload', data).then(res => res.data);
  },
  doUpdateProductDealsCsv: data => {
    return client.post('seller/product/deal', data).then(res => res.data);
  },
  doUpdateCommissionOrStatusCsv: data => {
    return client.post('seller/product/doUpdateCommissionOrStatusCsv', data).then(res => res.data);
  },
  getProductDetail: data => {
    return client.patch(`seller/product/detail/${data}`, data).then(res => res.data);
  },
  getCommissionHistory: data => {
    return client.get(`seller/product/commissionHistory?id=${data}`, data).then(res => res.data);
  },
  getBrandswithAvailableProducts: data => {
    return client.get(`seller/brands/with-available-products`, data).then(res => res.data);
  },
  getProductCount: data => {
    return client.get(`seller/product/productCount`, data).then(res => res.data);
  },
  getProductVariations: data => {//获取变种
    return client.post(`seller/product/variation/count`, data).then(res => res.data);
  },
  latestUpdateTime: data => {
    return client.get('seller/product/latest-update-time', data).then(res => res.data);
  },
  refetchData: data => {
    return client.post('seller/product/refetch', data).then(res => res.data);
  },
  // 保存Deal: 有ID用PUT，没有ID用POST
  saveDeal: data => {
    return client[data.id ? 'put' : 'post'](`seller/product/deal/edit`, data).then(res => res.data);
  },
  // 删除Deal
  deleteDeal: id => {
    return client.delete(`seller/product/deal/${id}`).then(res => res.data);
  }
}
